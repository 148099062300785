<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { EPMC_API_FT_REPO_ROOT, EPMC_ROOT, SEARCH_QUERY_LIMIT } from '@/config'
import { fetchAnnotationsCount, fetchPreprintFiles } from '@/api'
import { hasLoggedIn } from '@/helpers/account'
import { isValidSearchPath } from '@/helpers/search'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action, List, Modal } from 'epmc-patterns/components/v2'
import ArticleClaimPopup from '@/templates/ArticleClaimPopup'
import ExportCitation from '@/templates/ExportCitation'
import SearchTermCharacterLimitModal from '@/templates/SearchTermCharacterLimitModal'

const downloadPreprint = (key, value) => {
  fetchPreprintFiles(value).then((response) => {
    const { filename, mimeType } = response.files.find(
      (file) => file.type === 'pdf'
    )
    window.open(
      EPMC_API_FT_REPO_ROOT +
        key +
        '=' +
        value +
        '&type=FILE&fileName=' +
        filename +
        '&mimeType=' +
        mimeType
    )
  })
}

export default {
  components: {
    Action,
    ArticleClaimPopup,
    ExportCitation,
    List,
    Modal,
    SearchTermCharacterLimitModal,
  },
  // refactor later***
  props: {
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fulltextCount: 0,
      supplementaryFilesCount: 0,

      showExport: false,

      signedIn: false,
      queryInput: '',

      showClaimArticle: false,
      showCharacterLimitModal: false,
      isOnSearchPage: false,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('exportList', ['exportList']),
    ...mapState('search', ['searchParams']),
    page() {
      return this.$route.name
    },
    pprInEpmc() {
      const { abstract } = this
      return abstract && abstract.source === 'PPR' && abstract.inEPMC === 'Y'
    },
    pmcidInRange() {
      const { abstract } = this
      const pmcid = abstract && abstract.pmcid
      return (
        pmcid &&
        ((pmcid >= 'PMC7115812' && pmcid <= 'PMC7116900') ||
          (pmcid >= 'PMC7610345' && pmcid <= 'PMC7640350'))
      )
    },
    pdfLink() {
      const { abstract, page, pmcidInRange, pprInEpmc } = this
      let pdfLink = ''
      if (page === 'article') {
        // # means the link will be constructed when clicked
        if (abstract.hasPDF === 'Y') {
          const { fullTextUrlList } = abstract
          if (fullTextUrlList) {
            let pdf = fullTextUrlList.fullTextUrl.find(
              (url) => url.documentStyle === 'pdf' && url.site === 'Europe_PMC'
            )
            if (!pdf) {
              pdf = fullTextUrlList.fullTextUrl.find(
                (url) => url.documentStyle === 'pdf'
              )
            }
            if (pdf) {
              pdfLink = pdf.url.replace('https://europepmc.org/', EPMC_ROOT)
            } else if (abstract.pmcid) {
              const html = fullTextUrlList.fullTextUrl.find(
                (url) =>
                  url.availability.toLowerCase() === 'free' &&
                  url.url.includes(
                    'www.ncbi.nlm.nih.gov/pmc/articles/' + abstract.pmcid
                  )
              )
              if (html) {
                pdfLink =
                  EPMC_ROOT +
                  'backend/ptpmcrender.fcgi?accid=' +
                  abstract.pmcid +
                  '&blobtype=pdf'
              }
            }
          } else if (pprInEpmc || pmcidInRange) {
            pdfLink = '#'
          }
        }
      }
      return pdfLink
    },
    actions() {
      const {
        fulltextCount,
        supplementaryFilesCount,
        exportList,
        page,
        pdfLink,
        size,
      } = this
      const actions = []
      if (isValidSearchPath(page)) {
        actions.push(
          {
            id: 'get_citation',
            text:
              'Export citations ' +
              (exportList.length ? ' (' + exportList.length + ')' : ''),
            iconClass: 'fas fa-quote-left',
            onClick: () => this.onActionClicked('get_citation'),
          },
          {
            id: 'get_rss_feed',
            text: 'Subscribe to RSS',
            iconClass: 'fas fa-rss',
            onClick: () => this.onActionClicked('get_rss_feed'),
          }
        )
      } else if (page === 'article') {
        if (
          size !== 'mobile' &&
          (fulltextCount > 0 || supplementaryFilesCount > 0)
        ) {
          actions.push({
            id: 'annotations_pane',
            text: 'Annotations',
            iconClass: 'fas fa-eye',
            onClick: () => this.onActionClicked('annotations_pane'),
            thickSeparator: true,
            subActions: [],
            tooltip:
              'Terms are identified by text mining algorithms, developed by a variety of text mining groups.',
          })
          const annotationsPane = actions.find(
            (action) => action.id === 'annotations_pane'
          )
          if (fulltextCount > 0) {
            annotationsPane.subActions.push({
              id: 'full_text',
              text: `${
                this.abstract.hasFulltext ? 'In full text' : 'In abstract'
              } (${fulltextCount})`,
              onClick: () =>
                this.onActionClicked(
                  'full_text',
                  'In full text (' + fulltextCount + ')'
                ),
            })
          }
          if (supplementaryFilesCount > 0) {
            annotationsPane.subActions.push({
              id: 'supp_files',
              text: 'In supp. files (' + supplementaryFilesCount + ')',
              onClick: () =>
                this.onActionClicked(
                  'supp_files',
                  'In supp. files (' + supplementaryFilesCount + ')'
                ),
            })
          }
        }
        actions.push({
          id: 'get_citation',
          text: 'Get citation',
          iconClass: 'fas fa-quote-left',
          onClick: () => this.onActionClicked('get_citation'),
        })
        if (pdfLink) {
          actions.push({
            id: 'open_pdf',
            text: 'Open PDF',
            iconClass: 'fa fa-file-pdf',
            onClick: () => this.onActionClicked('open_pdf'),
          })
        }
        actions.push({
          id: 'claim_article',
          text: 'Claim to ORCID',
          iconClass: 'fab fa-orcid',
          onClick: () => this.onActionClicked('claim_article'),
        })
      }
      return actions
    },
  },
  async created() {
    const { abstract, page } = this

    if (isValidSearchPath) {
      this.isOnSearchPage = true
    }

    if (hasLoggedIn()) {
      this.getLoggedInOrcidId().catch(() => {})
    }

    if (window.location.href.includes('?claimWithOrcid')) {
      this.showClaimArticle = true
    }

    if (page === 'article') {
      const count = await fetchAnnotationsCount(abstract.source, abstract.id)
      this.fulltextCount = count.fulltext || count || 0
      this.supplementaryFilesCount = count.suppl || 0
      this.updateAnnotationsCount({
        fulltextCount: count.fulltext || count,
        supplementaryFilesCount: count.suppl,
      })
    }
  },
  methods: {
    ...mapMutations('article/annotations', [
      'changeShowAnnotationsPane',
      'setActiveTab',
      'updateAnnotationsCount',
    ]),
    ...mapActions('orcid', ['getLoggedInOrcidId']),
    onActionClicked(actionName, subActionText) {
      const {
        abstract,
        actions,
        page,
        pdfLink,
        pmcidInRange,
        pprInEpmc,
        searchParams: { query, sort, page: pageNumber },
      } = this
      switch (actionName) {
        case 'claim_article':
          this.showClaimArticle = true
          break
        case 'get_citation':
          if (query && query.length > SEARCH_QUERY_LIMIT) {
            this.showCharacterLimitModal = true
            return
          }
          this.showExport = true
          break
        case 'get_rss_feed':
          window.open(
            `/searchrss?query=${query}&sortBy=${sort}&page=${pageNumber}&mode=rss`
          )
          break
        case 'full_text':
        case 'annotations_pane':
          this.changeShowAnnotationsPane(true)
          this.setActiveTab('full_text')
          registerMatomoEvent('Article', 'Annotations', 'Panel opened')
          break
        case 'supp_files':
          this.changeShowAnnotationsPane(true)
          this.setActiveTab('supp_files')
          registerMatomoEvent('Article', 'Annotations', 'Panel opened')
          break
        case 'open_pdf':
          if (pprInEpmc) {
            downloadPreprint('pprId', abstract.id)
          } else if (pmcidInRange) {
            if (pdfLink === '#') {
              downloadPreprint('pmcid', abstract.pmcid)
            } else if (pdfLink) {
              window.open(pdfLink)
            } else {
              downloadPreprint('pmcId', abstract.pmcid)
            }
          } else {
            window.open(pdfLink)
          }
          break
      }

      if (page === 'article') {
        registerMatomoEvent(
          'Article',
          'Tools',
          subActionText ||
            actions.find((action) => action.id === actionName).text
        )
      }
    },
    closeClaimArticleModal() {
      if (window.location.href.includes('?claimWithOrcid')) {
        // Remove "claimWithOrcid" param from url
        const query = Object.assign({}, this.$route.query)
        delete query.claimWithOrcid
        this.$router.replace({ query })
      }
      this.showClaimArticle = false
      registerMatomoEvent('Article', 'Claim to ORCID', 'Close popup')
    },
  },
}
</script>
<template>
  <div>
    <modal
      v-if="showExport"
      :close-when-clicking-outside="true"
      class="large"
      @close="showExport = false"
    >
      <template slot="title">
        {{ isOnSearchPage ? 'Export citations' : 'Get citation' }}
      </template>
      <export-citation />
    </modal>

    <!-- refactor after article-claim-popup is refactored*** -->
    <modal
      v-if="showClaimArticle"
      class="large"
      @close="closeClaimArticleModal"
    >
      <template slot="title">Claim article</template>
      <article-claim-popup />
    </modal>

    <template v-if="size === 'mobile'">
      <action
        v-for="a in actions.reverse()"
        :id="a.id"
        :key="a.id"
        @click="a.onClick"
      >
        <i slot="icon" :class="a.iconClass" /><span class="mobile-hide">{{
          a.text
        }}</span>
      </action>
    </template>
    <list v-else :list="actions" class="action-menu">
      <div :id="item.id" slot-scope="{ item }">
        <action @click="item.onClick">
          <i slot="icon" :class="[item.iconClass, 'action-bar-icon']" />
          {{ item.text }}
        </action>
        <div v-if="item.subActions">
          <div
            v-for="subAction in item.subActions"
            :key="subAction.id"
            class="sub-action"
            @click="subAction.onClick"
          >
            {{ subAction.text }}
          </div>
        </div>
      </div>
    </list>
    <search-term-character-limit-modal
      v-if="showCharacterLimitModal"
      :query-length="searchParams.query.length"
      context="Export citations"
      @close="showCharacterLimitModal = false"
    />
  </div>
</template>
<style scoped lang="scss">
.no_top_margin {
  margin-top: 0;
}
.action-menu {
  margin-top: $base-unit * 9.5;
  .action {
    font-size: $base-unit * 3.75;
  }
  .action-bar-icon {
    font-size: $base-unit * 4.5;
  }
  .sub-action {
    margin-left: 32px;
    font-weight: 400;
    margin-top: 5px;
    color: #20699c;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: #5797c5;
    }
  }
  @media screen and (max-width: $breakpoint-small) {
    width: 50%;
  }
}
#claim_article:hover .fa-orcid {
  opacity: 0.8;
}
</style>
